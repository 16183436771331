import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VueGtag from "vue-gtag";
import VueYouTubeEmbed from 'vue-youtube-embed';
Vue.config.productionTip = false

import VueSilentbox from "vue-silentbox"

Vue.use(VueGtag, {
  config: { id: "UA-56910608-3" },
}, router);

Vue.use(VueSilentbox)

Vue.use(VueYouTubeEmbed)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
