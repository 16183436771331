import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { colors } from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: colors.blue.darken3,
                secondary: colors.lightBlue.darken2,
                accent: colors.amber.accent4,
                error: colors.red.accent2,
                info: '#2196F3',
                success: colors.green.darken1,
                warning: colors.lime.accent4
            },
            dark: {
                primary: colors.lightBlue.darken4,
                secondary: colors.lightBlue.darken2,
                accent: colors.amber.accent4,
                error: colors.red.accent2,
                info: '#2196F3',
                success: colors.green.darken1,
                warning: colors.lime.accent4,
            },

        },
    },
});
